import $ from "jquery";

$.easing["jswing"] = $.easing["swing"];

$.extend($.easing, {
  def: "easeOutQuad",
  easeOutQuad: function (x, t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b;
  },
});

import barba from "@barba/core";

import Swiper, { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay]);

import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "is-in-viewport";

import is from "is_js";

import "grained/grained.js";

if (is.touchDevice()) {
  $("html").addClass("mobile");
} else {
  $("html").addClass("desktop");
}

import "../sass/main.scss";

import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

import { Smoketype } from "./Smoketype.ts";

function initSmokeforLandigPage() {
  //console.log('initSmokeforLandigPage()');
  if ($(".detailPage").length) {
    //console.log($("#global-smoketype-container").length, "length");
  }
  window.smokeType = new Smoketype({
    SLOWMAXTIME: 200,
    FADEOUTMAXTIME: 500,
    STOPANIMATION: false,
    STOPEDPIXELSTEP: 0.001,
    ADDGRAIN: true,
    GUPDATE: 0.1,
  });

  let id = $(".animation-containerInline").data().id;
  let col1 = $(".animation-containerInline").data().col1;
  let col2 = $(".animation-containerInline").data().col2;
  let col3 = $(".animation-containerInline").data().col3;

  window.smokeType.createSim(id, {
    colorsHex: [col1, col2, col3],
    COLORMIXVALUE: 0.9,
    SIMMIXVALUE: 0.5,
    COLOR2INT: [0.2, 0.4],
    COLOR3INT: [0.5, 0.6],
    SLOWDOWN: 0.992,
    SLOWSPEED: 1.2,
    DARKTYPEBORDER: 200,
  });
  $("#global-smoketype-container").addClass("landing");

  setTimeout(() => {
    window.smokeType.runSimulationSlow(id);
  }, 300);

  gsap.set(".header-animation-container-stretch", {
    opacity: 0,
  });

  setTimeout(() => {
    window.smokeType.runSimulation(id, { duration: 6500 });
    gsap.to(".header-animation-container-stretch", {
      opacity: 1,
      delay: 3,
      duration: 2.5,
      onComplete: function () {
        if ($(document).scrollTop() > 0) {
        } else {
          gsap.to(window, { duration: 1, scrollTo: 80, ease: "power3.out" });
        }
      },
    });
  }, 600);
}

function initsmokeType() {
  $("#global-smoketype-container").removeAttr("style");
  $("#global-smoketype-container canvas").remove();

  window.smokeType = new Smoketype({
    SLOWMAXTIME: 9000,
    FADEOUTMAXTIME: 500,
    STOPANIMATION: false,
    STOPEDPIXELSTEP: 0.001,
    ADDGRAIN: true,
    GUPDATE: 0.1,
  });

  $(".animationDATA").each(function (index, data) {
    //console.log($(data));
    let id = $(data).data().id;
    //console.log(id);
    let col1 = $(data).data().col1;
    let col2 = $(data).data().col2;
    let col3 = $(data).data().col3;
    window.smokeType.createSim(id, {
      colorsHex: [col1, col2, col3],
      COLORMIXVALUE: 0.9,
      SIMMIXVALUE: 0.5,
      COLOR2INT: [0.2, 0.4],
      COLOR3INT: [0.5, 0.6],
      SLOWDOWN: 0.992,
      SLOWSPEED: 1.2,
      DARKTYPEBORDER: 200,
    });

    $(data)
      .find("img")
      .on("mouseover", function () {
        moveSmokeTypeContainer(id, window.smokeType);
        window.smokeType.runSimulationSlow(id);
        //console.log(smokeType);
        $(this).addClass("animateSmoke");
        $("#global-smoketype-container").removeClass("animate");
        $("#global-smoketype-container").addClass("animate");
      });

    $(data)
      .find("img")
      .on("mouseout", function () {
        window.smokeType.fadeOut(id);
        $(this).removeClass("animateSmoke");
        $("#global-smoketype-container").removeClass("animate");
      });
  });
}

function menuMouseMoveInit() {
  const halfSkewWidth = 30.0;
  const ring = document.getElementById("ring");
  var initialLoad = true;
  const svg = document.getElementById("nav-ring-svg");
  // mouse move
  document.addEventListener("mousemove", (e) => {
    ring.style.transform =
      "rotateZ(0deg) " + "rotateX(0deg)" + " " + "rotateY(0deg)";
    let mouseX = e.clientX;
    let mouseY = e.clientY;
    let relMouseX = mouseX / window.innerWidth;
    let relMouseY = mouseY / window.innerHeight;
    let skewY = relMouseX * halfSkewWidth * 2 - halfSkewWidth;
    let skewX = relMouseY * halfSkewWidth * 2 - halfSkewWidth;
    skewX *= -1.0;
    if (window.innerWidth < 800) {
      skewX = 0.0;
      skewY = 0.0;
    }
    skewX = Math.round(skewX * 1000) / 1000.0;
    skewY = Math.round(skewY * 1000) / 1000.0;

    const rotateYString =
      "rotateZ(0deg) " +
      "rotateX(" +
      skewX +
      "deg)" +
      " " +
      "rotateY(" +
      skewY +
      "deg)";

    ring.style.transform = rotateYString;
  });

  var navElemments = document.getElementsByClassName("nav-menu-point");
  for (let elem of navElemments) {
    elem.addEventListener("mouseenter", (e) => {
      elem.classList.add("animation");
      let nav = document.getElementsByClassName("nav-menu-point");
      for (let n of nav) {
        n.getElementsByClassName("nav-shadow-1")[0].classList.remove(
          "nav-shadow-1-visible"
        );
        n.getElementsByClassName("nav-shadow-1")[0].classList.remove(
          "nav-shadow-2-visible"
        );
      }

      let shadow1 = elem.getElementsByClassName("nav-shadow-1")[0];
      shadow1.classList.add("nav-shadow-1-visible");
      let shadow2 = elem.getElementsByClassName("nav-shadow-2")[0];
      shadow2.classList.add("nav-shadow-2-visible");
    });
    elem.addEventListener("mouseleave", (e) => {
      elem.classList.remove("animation");
      let shadow1 = elem.getElementsByClassName("nav-shadow-1")[0];
      shadow1.classList.remove("nav-shadow-1-visible");
      let shadow2 = elem.getElementsByClassName("nav-shadow-2")[0];
      shadow2.classList.remove("nav-shadow-2-visible");
    });
  }
}

function moveSmokeTypeContainer(id, elem) {
  if (!elem.isRunning()) {
    var renderCont = document.getElementById("global-smoketype-container");
    var typeCont = document.getElementById("animation-" + id);
    renderCont.style.top = typeCont.offsetTop + "px";
    renderCont.style.left = typeCont.offsetLeft + "px";
  }
}

$(document).ready(function () {
  if ($(".m-jumpMenu-Box").length > 0) {
    setTimeout(() => {
      $(".m-jumpMenu-Box").removeClass("init");
    }, 500);
  }
});

// https://waelyasmina.com/barba-js-v2-tutorial-for-total-beginners/

function delay(n) {
  n = n || 2000;
  // @ts-ignore
  return new Promise<void>((done) => {
    setTimeout(() => {
      done();
    }, n);
  });
}

barba.init({
  debug: false,
  timeout: 10000, // default is 2000ms
  transitions: [
    {
      name: "Default Transition",
      sync: false,
      beforeOnce() {},
      once() {},
      afterOnce() {},
    },

    {
      name: "To ProjectDetail",
      to: {
        namespace: "detailList",
      },
      beforeOnce() {},
      once() {},
      afterOnce() {},
      enter: (data) => {
        return new Promise((resolve) => {
          /*callbackAnimation(data.current.container, {
            onComplete: () => {
              resolve();
            },
          });*/

          gsap.to($(data.current.container).find("header"), {
            opacity: 0,
            duration: 0,
          });

          gsap.to($(data.current.container).find(".pageInline"), {
            opacity: 0,
            xPercent: 2,
            duration: 0.5,
          });

          gsap.set($(data.next.container).find(".pageInline"), {
            opacity: 0,
          });

          gsap.to($(data.next.container).find(".pageInline"), {
            opacity: 1,
            xPercent: 0,
            duration: 0.5,
            onComplete: () => {
              window.scrollTo(0, 0);
              resolve();
              $(data.next.container).find(".pageInline").removeAttr("style");
            },
          });

          gsap.to($(data.next.container).find("header"), {
            opacity: 1,
          });
        });
      },
    },
    {
      name: "To Contact",
      to: {
        namespace: "contact",
      },
      beforeOnce() {},
      once() {},
      afterOnce() {},
      enter(data) {
        if ($("html").hasClass("animate")) {
          $("html").removeClass("animate");
        }
      },
    },
    {
      name: "To Detail",
      to: {
        namespace: "detail",
      },
      async before(data) {
        if (data.current.namespace == "overview") {
          $("#global-smoketype-container").removeAttr("style");
          $("#global-smoketype-container canvas").remove();
        }

        if (data.current.namespace == "detail") {
          setTimeout(() => {
            initSmokeforLandigPage();
          }, 1000);
        }

        if (data.current.namespace == "home") {
          $("#global-smoketype-container").css({ "z-index": "5" });
          const done = this.async();
          $(".hero").css("opacity", 0);
          $("html, body").animate(
            {
              scrollTop: $(data.trigger).offset().top,
            },
            400
          );
          await delay(400);
          done();

          let aminationid = $(data.trigger).data().aminationid;
          window.smokeType.runSimulation(aminationid, {
            duration: 6500,
          });

          await delay(6500);
          data.current.container.remove();
          done();
        }
      },
      async leave(data) {
        if (data.current.namespace == "home") {
          const done = this.async();
          gsap.to(data.current.container, {
            duration: 0.5,
            opacity: 0,
          });
          await delay(200);
          done();
        }
      },
      async beforeEnter(data) {
        if (data.current.namespace == "detail") {
          gsap.set(".header-animation-container-stretch", {
            opacity: 0,
          });
        }
        if (data.current.namespace == "overview") {
          gsap.set(".header-animation-container-stretch", {
            opacity: 0,
          });

          gsap.to(".header-animation-container-stretch", {
            opacity: 1,
            delay: 3,
            duration: 3,
          });
          //console.log(data.current.namespace + ' tomi');
        }

        if (data.current.namespace == "home") {
          const done = this.async();
          $("#global-smoketype-container").css({
            position: "fixed",
            "z-index": "-1",
            top: "0px",
            left: "0px",
            opacity: 1,
          });
          window.scrollTo(0, 0);
          gsap.set(".header-animation-container-stretch", {
            opacity: 0,
          });
          await delay(100);
          done();
          setTimeout(() => {
            gsap.to(".header-animation-container-stretch", {
              opacity: 1,
              delay: 3,
              duration: 2.5,
              onComplete: function () {
                if ($(document).scrollTop() > 0) {
                } else {
                  gsap.to(window, {
                    duration: 1,
                    scrollTo: 80,
                    ease: "power3.out",
                  });
                }
              },
            });
          }, 10);
        }

        if (
          data.current.namespace == "overview" ||
          data.current.namespace == "contact" ||
          data.current.namespace == "teamOverview" ||
          data.current.namespace == "jazz"
        ) {
          setTimeout(() => {
            initSmokeforLandigPage();
          }, 300);
        }
      },
    },
    {
      name: "To Overview",
      to: {
        namespace: "overview",
      },
      async before(data) {
        const done = this.async();
        $("#global-smoketype-container").removeAttr("style");
        $("#global-smoketype-container canvas").remove();
        await delay(500);
        done();
      },
      async enter(data) {
        const done = this.async();

        await delay(500);
        done();
      },
    },
    {
      mame: "toTeam",
      to: {
        namespace: "teamOverview",
      },
      once() {},
      leave() {},
      enter() {},
    },

    {
      name: "To Home",
      to: {
        namespace: "home",
      },
      // @ts-ignore
      once(data) {
        /* gsap.set(".hero .word", { opacity: 0, xPercent: -100, scale: 2 });
        gsap.to(".hero .word", {
          stagger: 0.25,
          opacity: 1,
          xPercent: 0,
          scale: 1,
          delay: 0.5,
          ease: "power2.out",
          force3D: true,
        });*/
      },
      leave(data) {
        /*
        $("#global-smoketype-container").removeAttr("style");
        $("#global-smoketype-container canvas").remove();*/
      },
      enter(data) {
        if ($("#global-smoketype-container").hasClass("landing")) {
          $("#global-smoketype-container").removeClass("landing");
        }

        $("#global-smoketype-container").removeAttr("style");
        $("#global-smoketype-container canvas").remove();

        setTimeout(() => {
          initsmokeType();
        }, 1000);
      },
    },
  ],
});

$("body").on("click", ".toogleMenu", function (e) {
  e.preventDefault();
  $("html").toggleClass("nav-visible");
  $("#nav-ring-svg").removeClass("explodeAnimation");
  $("#nav-ring-svg").toggleClass("init");
});

barba.hooks.leave((data) => {
  if ($(".animation-containerInline").length) {
    //console.log("barba.hooks.leave -> reset Smoketype Container");
    gsap.to("#global-smoketype-container", {
      opacity: 0,
      onComplete: function () {
        $("#global-smoketype-container").removeAttr("style");
        $("#global-smoketype-container canvas").remove();
      },
    });
  }

  for (var i = 0; i < timerID.length; i++) {
    clearTimeout(timerID[i]);
  }
  timerID = [];
});

barba.hooks.beforeEnter(() => {
  //console.log('Hook beforeEnter()');
  if ($("html").hasClass("nav-visible")) {
    menuMouseMoveInit();
    $("#nav-ring-svg").addClass("explodeAnimation");
    $("#nav-link-ball").addClass("animation");
    setTimeout(() => {
      $("html").removeClass("nav-visible");
      setTimeout(() => {
        $("#nav-ring-svg").removeClass("explodeAnimation init");
        $("#nav-link-ball").removeClass("animation");
      }, 1200);
    }, 800);
  }
});

barba.hooks.enter((data) => {
  /*$("#global-smoketype-container").removeAttr("style");
    $("#global-smoketype-container canvas").remove();*/

  //console.log('######### ' + data.next.namespace );
  if (data.next.namespace == "detail") {
    $("html").addClass("detailHTML");
  } else {
    $("html").removeClass("detailHTML");
  }

  window.scrollTo(0, 0);

  if (data.next.namespace == "contact") {
    window.scrollTo(0, 0);
    /* fetch("/actions/snaptcha/field/get-field")
      .then((result) => {
        return result.text();
      })
      .then((result) => {
        //console.log(result);
        $("form").append(result);
      });*/
  }

  if ($(".headerColorWhite".length)) {
    //console.log('headerColorWhite');
  } else {
    // console.log('not headerColorWhite');
  }

  setTimeout(() => {
    AOS.init();
  }, 10);

  setTimeout(() => {
    if ($("header").hasClass("headerColorWhite")) {
      $("#cursor-small .circle, .m-jumpMenu-Box").addClass("color-white");
    } else {
      $("#cursor-small .circle, .m-jumpMenu-Box").removeClass("color-white");
    }
  }, 200);

  setTimeout(() => {
    if ($(".homePage .animation-container").length > 0) {
      initsmokeType();
    }
  }, 300);
});

barba.hooks.once((data) => {
  //console.log('?????');

  if (webgl_support) {
    //console.log('WebGL Support!');
  } else {
    var postData = {};
    postData[csrfTokenName] = csrfTokenValue;
    $.ajax({
      type: "post",
      url: "/ajax/webgl.twig",
      data: postData,
      success: function (data) {
        //console.log(data);
        $("body").append(data);
        setTimeout(() => {
          $(".dialog-window").addClass("dialog-window-animate");
          $("html").addClass("dialogWindow");
        }, 100);
        $("body").on("click", ".dialog-window .arrow-container", function (e) {
          e.preventDefault();
          $(".dialog-window").removeClass("dialog-window-animate");
          setTimeout(() => {
            $(".dialog-window").remove();
            $("html").removeClass("dialogWindow");
          }, 600);
        });
      },
    });
  }

  if (data.next.namespace == "detail") {
    initSmokeforLandigPage();
  }
  if (data.next.namespace == "detail") {
    $("html").addClass("detailHTML");
  }

  if (data.next.namespace == "home") {
    initsmokeType();
  }

  if ($("header").hasClass("headerColorWhite")) {
    $("#cursor-small .circle, .m-jumpMenu-Box").addClass("color-white");
  } else {
    $("#cursor-small .circle, .m-jumpMenu-Box").removeClass("color-white");
  }
  menuMouseMoveInit();
});

barba.hooks.after(() => {
  menuMouseMoveInit();
  initSwipers();
  //initsmokeType();
  grained("#grain", {
    animate: false,
    patternWidth: 200,
    patternHeight: 200,
    grainOpacity: 0.06,
    grainDensity: 1,
    grainWidth: 1,
    grainHeight: 1,
  });

  //console.log('Title: ' + document.title);
  _paq.push(["setCustomUrl", "/" + window.location.hash.substr(1)]);
  _paq.push(["setDocumentTitle", document.title]);
  _paq.push(["trackPageView"]);

  if (history.scrollRestoration) {
    history.scrollRestoration = "manual";
  }
  window.scrollTo(0, 0);

  if ($("header").hasClass("headerColorWhite")) {
    $("#cursor-small .circle, .m-jumpMenu-Box").addClass("color-white");
  } else {
    $("#cursor-small .circle, .m-jumpMenu-Box").removeClass("color-white");
  }

  if ($(".m-jumpMenu-Box").length > 0) {
    setTimeout(() => {
      $(".m-jumpMenu-Box").removeClass("init");
    }, 500);
  }

  $(window).scroll(function () {
    if ($("html").hasClass("mobile")) {
      if ($(this).scrollTop() > ($(window).height() / 100) * 50) {
        if ($("main").hasClass("detailPage")) {
          $("html").addClass("animate");
        }
      } else {
        if ($("main").hasClass("detailPage")) {
          $("html").removeClass("animate");
        }
      }
    }

    if ($("html").hasClass("desktop")) {
      if ($(this).scrollTop() > ($(window).height() / 100) * 100 + 50) {
        if ($("main").hasClass("detailPage")) {
          $("html").addClass("animate");
        }
      } else {
        if ($("main").hasClass("detailPage")) {
          $("html").removeClass("animate");
        }
      }
    }

    let windowTop = $(this).scrollTop();

    $(".m-jumpMenu-Box ul li span").each(function (event) {
      let elem = $(this);
      let sectionID = $(elem).data().id;
      let section = $("main").find(sectionID);
      if (
        windowTop >=
        $(section).offset().top - ($(window).height() / 100) * 100
      ) {
        $(".m-jumpMenu-Box ul li.active").removeClass("active");
        $(this).parent().addClass("active");
      }
    });
  });
});

$(window).scroll(function () {
  if ($("html").hasClass("mobile")) {
    if ($(this).scrollTop() > ($(window).height() / 100) * 50) {
      if ($("main").hasClass("detailPage")) {
        $("html").addClass("animate");
      }
    } else {
      if ($("main").hasClass("detailPage")) {
        $("html").removeClass("animate");
      }
    }
  }

  if ($("html").hasClass("desktop")) {
    if ($(this).scrollTop() > ($(window).height() / 100) * 100 + 50) {
      if ($("main").hasClass("detailPage")) {
        $("html").addClass("animate");
      }
    } else {
      if ($("main").hasClass("detailPage")) {
        $("html").removeClass("animate");
      }
    }
  }

  let windowTop = $(this).scrollTop();

  $(".m-jumpMenu-Box ul li span").each(function (event) {
    let elem = $(this);
    let sectionID = $(elem).data().id;
    let section = $("main").find(sectionID);
    if (
      windowTop >=
      $(section).offset().top - ($(window).height() / 100) * 80
    ) {
      $(".m-jumpMenu-Box ul li.active").removeClass("active");
      $(this).parent().addClass("active");
    }
  });
});

$(document).ready(function () {
  initSwipers();
});

function initSwipers() {
  $(".info .infoContainer .swiper").each(function (index, element) {
    $(this).addClass("s" + index);
    let sid = $(this).parent().parent().data().swiperid;
    //console.log('#'+sid+' .swiper-pagination');
    let swiper = new Swiper(".s" + index, {
      // Optional parameters
      loop: true,
      pagination: {
        el: "#" + sid + " .swiper-pagination",
        clickable: true,
      },
    });
  });

  $(".c-storySlider .swiper").each(function (index, element) {
    $(this).addClass("s" + index);
    let sid = $(this).parent().data().swiperid;
    //console.log('#' + sid + ' .swiper-pagination');
    //console.log('#' + sid + ' .swiper-scrollbar');
    let swiper = new Swiper(".s" + index, {
      // Optional parameters
      loop: true,
      autoplay: {
        delay: 4000,
      },
      navigation: {
        nextEl: "#" + sid + " .swiper-button-next",
        prevEl: "#" + sid + " .swiper-button-prev",
      },
    });
  });
}

$("body").on("click", ".accordionBox .trigger", function (e) {
  //console.log($(this));
  $(this).toggleClass("animate");
  $(this)
    .next()
    .slideToggle("fast", function () {
      // Animation complete.
    });
});

let mouse = { x: 0, y: 0 };
let boxPos = { x: 0, y: 0 };
let timerID = [];
let currentTimerID = null;

$("body").on("mouseenter", ".m-relatedProjects a.row", function (evt) {
  //$(this).parent().addClass("disable");
  //$(this).addClass("focus");
  let imageBox = $(this).find(".quadrat");
  currentTimerID = setInterval(function () {
    followMouse(imageBox);
  }, 30);
  timerID.push(currentTimerID);
});

$("body").on("mouseleave", ".m-relatedProjects a.row", function (e) {
  //let imageBox = $(this).find(".quadrat");
  //$(this).parent().removeClass("disable");
  //$(this).removeClass("focus");
  for (var i = 0; i < timerID.length; i++) {
    clearTimeout(timerID[i]);
  }
  timerID = [];
});

// Project List Toogle Info Box for Mobiles!

/*$(".mobile body").on(
  "click",
  ".m-project-list .row .title, .m-project-list .row .tags",
  function (evt) {
    evt.preventDefault();
    let elem = $(this);
    $(".m-project-list .info").removeClass("showMobile");
    elem.parent().find(".info").toggleClass("showMobile");
    $(".m-project-list .row").removeClass("focus");
    elem.parent().toggleClass("focus");
    
  }
);
*/

// https://codepen.io/soniaanubis/pen/YvPLad

function followMouse(imageBox) {
  if ($(".animationDATA").length) {
    var box = document.getElementById($(imageBox).attr("id"));
    var distX = mouse.x - boxPos.x;
    var distY = mouse.y - boxPos.y;

    boxPos.x += distX / 5;
    boxPos.y += distY / 2;

    console.log(boxPos.x, boxPos.y);

    box.style.left = boxPos.x + "px";
    box.style.top = boxPos.y + "px";
  }
}

function getMouse(e) {
  mouse.x = e.clientX;
  mouse.y = e.clientY;
}

document.addEventListener("mousemove", getMouse);

$(".desktop body").on("mouseenter", ".m-project-list .row", function (evt) {
  $(this).parent().addClass("disable");
  $(this).addClass("focus");
  let imageBox = $(this).find(".info");
  $(imageBox).css("border-radius", "50px");
  mouse.x = evt.clientX;
  mouse.y = evt.clientY;

  currentTimerID = setInterval(function () {
    followMouse(imageBox);
  }, 30);
  timerID.push(currentTimerID);
});

$(".desktop body").on(
  "mousemove",
  ".m-project-list .row, .m-relatedProjects a",
  function (evt) {
    evt.preventDefau;
    let imageBox = $(this);
    let images = $(imageBox).find(".imagesContainerDYN");
    let startPoint = $(imageBox).offset().left;
    let endPoint = $(imageBox).offset().left + $(imageBox).width();
    let boxWidth = $(imageBox).width();
    let boxWidthSection = $(imageBox).width() / 3;
    let section1 = $(imageBox).offset().left + boxWidthSection;
    let section2 = section1 + boxWidthSection;
    let section3 = section2 + boxWidthSection;

    if (evt.clientX < section1) {
      //console.log(images);
      $(images).find(".image-container").removeClass("active");
      $(images).find(".ic-1").addClass("active");
    }
    if (evt.clientX > section1 && evt.clientX < section2) {
      $(images).find(".image-container").removeClass("active");
      $(images).find(".ic-2").addClass("active");
    }

    if (evt.clientX > section2 && evt.clientX < section3) {
      $(images).find(".image-container").removeClass("active");
      $(images).find(".ic-3").addClass("active");
    }
  }
);

$(".desktop body").on("mouseleave", ".m-project-list .row", function (e) {
  let imageBox = $(this).find(".info");
  $(this).parent().removeClass("disable");
  $(this).removeClass("focus");
  for (var i = 0; i < timerID.length; i++) {
    clearTimeout(timerID[i]);
  }
  timerID = [];
});

$("body").on("click", ".m-jumpMenuBg", function (evt) {
  $("html").removeClass("showJumpMenu");
  $(".m-jumpMenu-Box").removeClass("animate");
});

$("body").on("click", ".m-jumpMenu-Box li", function (evt) {
  if ($("html").hasClass("showJumpMenu")) {
    let elemID = $(this).find("span").data().id;
    let elem = $(this);

    if (elemID == "#top") {
      window.jumpPos = 0;
      window.scrollTOJump = 0;
    } else {
      window.jumpPos = $(elemID).offset().top;
      window.scrollTOJump = window.jumpPos - ($(window).height() / 100) * 30;
    }
    $("html").removeClass("showJumpMenu");

    //console.log(elem, pos);

    setTimeout(() => {
      $(".m-jumpMenu-Box").removeClass("animate");
    }, 100);

    setTimeout(() => {
      $("html, body").animate(
        {
          scrollTop: window.scrollTOJump,
        },
        800,
        "easeOutQuad"
      );
    }, 400);
  } else {
    $("html").addClass("showJumpMenu");
    setTimeout(() => {
      $(".m-jumpMenu-Box").addClass("animate");
    }, 10);
  }
});

$(document).ready(function () {
  grained("#grain", {
    animate: false,
    patternWidth: 200,
    patternHeight: 200,
    grainOpacity: 0.06,
    grainDensity: 1,
    grainWidth: 1,
    grainHeight: 1,
  });
});

$("body").on("click", ".footerdropDown", function () {
  $("#footerNavBox").show();
  $("html").toggleClass("pageBlurFooter");
  $(".footerdropDown").toggleClass("animate");
  if ($("#footerNavBox").hasClass("backwardAnimationState")) {
    $("#footerNavBox").removeClass("animate-footerNavBox");
    setTimeout(() => {
      $("#footerNavBox").removeClass("backwardAnimationState");
    }, 1000);
  } else {
    setTimeout(() => {
      $("#footerNavBox").addClass("animate-footerNavBox");
      setTimeout(() => {
        $("#footerNavBox").addClass("backwardAnimationState");
      }, 1000);
    }, 50);
  }
});

// Mouse Move

window.mouseX = 0;
window.mouseY = 0;

window.Xsmall = 0;
window.Ysmall = 0;
window.smallCursor = document.getElementById("cursor-small");

function mousemove(e) {
  window.mouseX = e.clientX;
  window.mouseY = e.clientY;

  let targetELEM = e.target.nodeName || e.srcElement.nodeName;
  let targetClass = e.target.className || e.srcElement.className;
  let targetID = e.target.id || e.srcElement.id;

  console.log(targetClass);
  if (
    targetELEM == "A" ||
    targetELEM == "SELECT" ||
    targetELEM == "TEXTAREA" ||
    targetELEM == "INPUT" ||
    targetELEM == "LABEL" ||
    targetClass == "option" ||
    targetClass == "link" ||
    targetClass == "animation-textimage animateSmoke" ||
    targetClass == "trigger link" ||
    targetClass == "footerdropDown link" ||
    targetClass == "arrowline" ||
    targetClass == "arrowtext" ||
    targetClass == "trigger link animate" ||
    targetClass == "memberlink lazyloaded" ||
    targetClass == "memberlink ls-is-cached lazyloaded" ||
    targetClass == "memberlink" ||
    targetClass == "arrow-container arrow-small" ||
    targetClass == "hr-arrow" ||
    targetClass == "cross" ||
    targetClass == "plus" ||
    targetClass == "p1" ||
    targetClass == "p2" ||
    targetClass == "socialLink" ||
    targetClass == "btn_toggle_filter" ||
    targetClass == "inner" ||
    targetClass == "filter-delete" ||
    targetClass == "labelFile" ||
    targetClass == "removeFile" ||
    targetClass == "removeFileIco" ||
    targetClass == "fileIco" ||
    targetID == "fileSelectID" ||
    targetClass == "mousePoinert02 lazyloaded" ||
    targetClass == "enableMixedBlendModemultiply mousePoinert02 lazyloaded" ||
    targetID == "content-active-area" ||
    targetID == "team-active-area" ||
    targetID == "projects-active-area" ||
    targetID == "jazz-active-area"
  ) {
    window.smallCursor.classList.add("cursor-small-animate");
  } else {
    window.smallCursor.classList.remove("cursor-small-animate");
  }

  if (targetClass == "logo-link") {
    window.smallCursor.classList.add("cursor-small-animate-logo");
  } else {
    window.smallCursor.classList.remove("cursor-small-animate-logo");
  }
}

window.addEventListener("mousemove", mousemove);

$(document).ready(function () {
  animate();
});

function animate() {
  window.Xsmall += (window.mouseX - window.Xsmall) * 0.6;
  window.Ysmall += (window.mouseY - window.Ysmall) * 0.6;

  window.smallCursor.style.transform =
    "translate3d(" + window.Xsmall + "px ," + window.Ysmall + "px ,0)";

  window.requestAnimationFrame(animate);
}

// Newsletter Anmeldung
$("body").on("click", "#newsletterForm .submitBox input", function (ev) {
  ev.preventDefault();
  var test = $("#newsletterForm")[0].reportValidity();

  if (test) {
    submitFormNewsLetter();
  }
});

function submitFormNewsLetter() {
  var form = $("#newsletterForm");
  var actionUrl = $("#newsletterForm").attr("action");

  $.ajax({
    type: "POST",
    url: actionUrl,
    data: form.serialize(),
    success: function (data) {
      //console.log("Data: " + data);
      $(".m-newsletter").addClass("success");
      $("#newsletterForm")[0].reset();
      setTimeout(() => {
        $("#emailInput").val("");
        $(".m-newsletter").removeClass("success");
      }, 10000);
    },
    error: function (data) {
      //console.log("Error: " + data);
    },
  });
}

/*
$("#newsletterForm").submit(function (e) {
  e.preventDefault(); // avoid to execute the actual submit of the form.

  var form = $(this);
  var actionUrl = form.attr("action");

  $.ajax({
    type: "POST",
    url: actionUrl,
    data: form.serialize(),
    success: function (data) {
      console.log("Data: " + data);
      $(".m-newsletter").addClass("success");
      $("#newsletterForm")[0].reset();
      setTimeout(() => {
        $("#emailInput").val("");
        $(".m-newsletter").removeClass("success");
      }, 10000);
    },
    error: function (data) {
      console.log("Error: " + data);
    },
  });

  return false;
});
*/

$("body").on("click", ".btn_toggle_filter", function (evt) {
  $("html").toggleClass("filter-visible");
});

$("body").on("click", ".project_overviewFilter a", function (evt) {
  evt.preventDefault();
  $("html").removeClass("filter-visible");
  let filterName = $(this).data().fname;
  let filterID = $(this).data().filter;
  $(".btn_toggle_filter").text(filterName);
  $(".filter-delete").show();
  $(".project_overviewFilter a").removeClass("active");
  $(this).addClass("active");

  $(".m-load-more ").addClass("hide");

  gsap.to(".m-project-list", {
    opacity: 0,
    onComplete: function () {
      $(".m-project-list").empty();
      var postData = {};
      postData["filterID"] = filterID;
      postData[csrfTokenName] = csrfTokenValue;
      $.ajax({
        type: "post",
        url: "/ajax/projectList.twig",
        data: postData,
        success: function (data) {
          $(".m-project-list").append(data);
          initSwipers();
          gsap.set(".m-project-list", { opacity: 0 });
          gsap.to(".m-project-list", { opacity: 1 });
        },
      });
    },
  });
});

$("body").on("click", ".m-load-more .flex", function (evt) {
  evt.preventDefault();
  let btn = $(this).find("a");
  let amount = parseInt($(this).find("a").attr("data-amount"));
  let offset = parseInt($(this).find("a").attr("data-offset"));
  let max = parseInt($(this).find("a").data().max);
  let nextOffset = offset + amount;
  $(btn).attr("data-offset", nextOffset);
  let postData = {};

  $(".m-load-more .plus").addClass("rotate");

  postData["nextOffset"] = nextOffset;
  postData["amount"] = amount;
  postData[csrfTokenName] = csrfTokenValue;

  if (nextOffset + amount >= max) {
    $(".m-load-more ").addClass("hide");
  }
  $.ajax({
    type: "post",
    url: "/ajax/projectListAdd.twig",
    data: postData,
    success: function (data) {
      $(".m-project-list .c1").append(data);
      $(".m-load-more .plus").removeClass("rotate");
      initSwipers();
      gsap.to(".m-project-list", { opacity: 1 });
      $(".m-project-list .new-item").addClass("fadeIn");
    },
  });
});

$("body").on("click", ".filter-delete", function (evt) {
  $(".btn_toggle_filter").text("Filter setzen");
  $("html").removeClass("filter-visible");
  $(".filter-delete").hide();
  $(".m-project-list .filterItem").show();
  $(".m-project-list").empty();
  $(".project_overviewFilter a").removeClass("active");
  $(".m-load-more ").removeClass("hide");
  $(".m-load-more .flex a").attr("data-offset", 0);
  gsap.to(".m-project-list", {
    opacity: 0,
    onComplete: function () {
      $(".m-project-list").empty();
      var postData = {};
      postData["filterID"] = "0";
      postData[csrfTokenName] = csrfTokenValue;
      $.ajax({
        type: "post",
        url: "/ajax/projectList.twig",
        data: postData,
        success: function (data) {
          $(".m-project-list").append(data);
          initSwipers();
          gsap.set(".m-project-list", { opacity: 0 });
          gsap.to(".m-project-list", { opacity: 1 });
        },
      });
    },
  });
});

/*$("body").on("click", ".project_overviewFilter a", function (evt) {
  evt.preventDefault();
  $(".filterItem").removeClass("disabledProject highlightProject");
  let elem = $(this);
  $(".project_overviewFilter a").removeClass("active");
  $(elem).addClass("active");

  let selector = ".f-" + $(elem).data().filter;

  if (selector == ".f-all") {
    $(".filterItem").removeClass("disabledProject highlightProject");
  } else {
    $(selector).addClass("highlightProject");
    $(".filterItem").addClass("disabledProject");
  }
});*/

/*$("body").on("click", "#contact-form .button", function (ev) {
  var test = $("#contact-form")[0].reportValidity();
  if (test) {
    submitForm();
  }
});*/

const form = document.getElementById("contact-form");

form.addEventListener("submit", async (e) => {
  e.preventDefault();

  $(".fileName").addClass("sending");
  $(".fileName").text("Datei wird hochgeladen!");

  const formData = new FormData(form);
  fetch("/actions/snaptcha/field/get-field")
    .then((result) => {
      return result.text();
    })
    .then((result) => {
      //console.log(result);
      $("form").append(result);
    });

  try {
    const response = await fetch("/contact-form/send", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Form submission successful", data);

    $(".fileName").removeClass("sending");
    $("#fileAttachment").val("");
    $(".removeFile").hide();
    $(".fileName").text("");
    $(".fileName").hide();
    $(".fileselect").show();
    $(".fileSize").hide();
    $(".fileSize").text("");

    $("#contact-form")[0].reset();
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      200
    );
    $("html").addClass("contactTHX");
    setTimeout(() => {
      $("html").removeClass("contactTHX");
    }, 4000);
  } catch (error) {
    console.error("Form submission error", error);
  }
});

function submitForm() {
  let formData = new FormData($("#contact-form")[0]);
  console.log(formData);

  $.ajax("/", {
    url: "/",
    data: formData,
    type: "POST",
    async: true,
    cache: false,
    contentType: false,
    processData: false,
    complete: function (jqXHR, textStatus) {
      console.log(jqXHR);
      console.log(textStatus);

      $("#fileAttachment").val("");
      $(".removeFile").hide();
      $(".fileName").text("");
      $(".fileName").hide();
      $(".fileselect").show();
      $(".fileSize").hide();
      $(".fileSize").text("");

      $("#contact-form")[0].reset();
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        200
      );
      $("html").addClass("contactTHX");
      setTimeout(() => {
        $("html").removeClass("contactTHX");
      }, 4000);
    },
  });
}

$("body").on("change", ".selectBoxesContainer select", function (e) {
  let selection = this.value;

  let trigger = $(this).prev();
  let label = $(trigger).data().label;
  let out = null;

  if (selection == "--") {
    out = label;
  } else {
    out = label + ": " + selection;
  }

  $(trigger).find(".content").text(out);
});

function webgl_support() {
  try {
    var canvas = document.createElement("canvas");
    return (
      !!window.WebGLRenderingContext &&
      (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
    );
  } catch (e) {
    return false;
  }
}

$(window).scroll(function () {
  if ($(this).scrollTop() > 50) {
    $("html").addClass("topOffset");
  } else {
    $("html").removeClass("topOffset");
  }
});

function getScrollHeight(elm) {
  var savedValue = elm.value;
  elm.value = "";
  elm._baseScrollHeight = elm.scrollHeight;
  elm.value = savedValue;
}

function getFontSize(elm) {
  var elem = elm;
  var style = window.getComputedStyle(elem, null).getPropertyValue("font-size");
  var fontSize = parseFloat(style);
  switch (fontSize) {
    case 18:
      return 24;
    case 24:
      return 38;
    case 32:
      return 50;

    default:
      break;
  }
}

function onExpandableTextareaInput({ target: elm }) {
  // make sure the input event originated from a textarea and it's desired to be auto-expandable
  if (!elm.classList.contains("autoExpand") || !elm.nodeName == "TEXTAREA")
    return;

  var minRows = 1,
    rows;
  var divider = getFontSize(elm);
  !elm._baseScrollHeight && getScrollHeight(elm);

  elm.rows = minRows;
  rows = Math.ceil((elm.scrollHeight - elm._baseScrollHeight) / divider);
  elm.rows = minRows + rows;
}

// global delegated event listener
document.addEventListener("input", onExpandableTextareaInput);

$("body").on("change", "#fileAttachment", function (e) {
  console.log(this.files[0].size);
  if (this.files[0].size >= 10000000) {
    $(".fileName").text($(".labelFile").data().errorfilesize);
    $("#fileAttachment").val("");
    $(".fileName").show();
    $(".fileselect").hide();
    $(".fileName").addClass("error");
    setTimeout(() => {
      $(".fileName").hide();
      $(".fileName").removeClass("error");
      $(".fileselect").show();
      $(".fileName").val("");
    }, 3000);
  } else {
    $(".removeFile").show();
    if (this.files[0].name.length > 35) {
      let suffix = getFileTypeSuffix(this.files[0].name);
      $(".fileName").text(
        trimAndAddPoints(this.files[0].name.slice(0, length - 4)) + suffix
      );
    } else {
      $(".fileName").text(this.files[0].name);
    }

    $(".fileName").show();
    $(".fileselect").hide();
  }
});
$("body").on("click", ".removeFile", function (e) {
  e.preventDefault();
  $("#fileAttachment").val("");
  $(".removeFile").hide();
  $(".fileName").text("");
  $(".fileName").hide();
  $(".fileselect").show();
});

function formatBytesToMegabytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 MB";

  const megabytes = (bytes / 1048576).toFixed(decimals);

  return `(${megabytes} MB)`;
}

function trimAndAddPoints(string) {
  const trimmedString = string.slice(0, 35); // Trim to 30 characters
  const points = "..."; // Define three points to add to end
  const result = trimmedString + points; // Add points to end
  return result;
}

function getFileTypeSuffix(fileName) {
  const fileExtension = fileName.split(".").pop(); // Extract file extension
  let suffix; // Initialize suffix variable
  switch (fileExtension) {
    case "pdf":
      suffix = "pdf";
      break;
    case "PDF":
      suffix = "pdf";
      break;
    case "jpg":
      suffix = "jpg";
      break;
    case "JPG":
      suffix = "jpg";
      break;
    case "zip":
      suffix = "zip";
      break;
    case "ZIP":
      suffix = "zip";
      break;
    default:
      suffix = "Unknown"; // Default suffix if file type is not recognized
  }
  return suffix;
}
